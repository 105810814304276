.mt-5 {
  margin-top: -5px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt14 {
  margin-top: 14px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt23 {
  margin-top: 23px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 30px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.f12 {
  font-size: 12px !important;
}

.f14 {
  font-size: 14px !important;
}

.f16 {
  font-size: 16px !important;
}

.f18 {
  font-size: 18px !important;
}

.f20 {
  font-size: 20px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pt10 {
  padding-top: 10px !important;
}

.f20 {
  font-size: 20px !important;
}

.f24 {
  font-size: 24px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.clearFix {
  clear: both;
}

.minwauto {
  min-width: auto !important;
}

.cursorPointer {
  cursor: pointer;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w33 {
  width: 33.33%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w100 {
  width: 100%;
}

.w80Px {
  width: 80px;
}

.w300 {
  width: 300px !important;
}

.banIcon {
  i.large.icon, i.large.icons {
    font-size: 1.2em;
  }
}

hr {
  height: 1px !important;
  border: none !important;
  background: #e9e9e9 !important;
  float: left;
  width: 100%;
}

a, div, tr, td, i {
  transition: all 0.3s ease-in-out;
}

b, strong {
  font-weight: normal !important;
  font-family: 'SegoeUI-Semibold', serif !important;
}

i {
  font-size: 14px;
  color: #96989d;
}

h3 small code {
  font-size: 80% !important;
}

.iconColor {
  color: #96989d !important;
}

.ui.dropdown > .dropdown.icon {
  font-size: 0.85714286em !important;
}

.ui.labeled.icon.buttons > .button > .icon, .ui.labeled.icon.button > .icon {
  background: transparent !important;
}

.bgPrimary {
  background: #f5b439;
}

.bgLightYellow {
  background: #FDF0D8 !important;
}

.rowLocked {
  background: #ffa6a6 !important;
}

#root {
  float: left;
  width: 100%;
  height: 100% !important;
}

main {
  height: 100%;
}

/*------------------------------------*-Body-*----------------------------------*/
html, body.crossRoads {
  font-family: 'SegoeUI-Regular', serif;
  font-size: 14px;
  color: #262626;
  width: 100%;
  background: #EBEFF1;
  height: 100% !important;
}

/*------------------------------------*-header-*----------------------------------*/
header {
  position: fixed;
  width: 100%;
  z-index: 999;
}

/*------------------------------------*-Grid-*----------------------------------*/
.crossRoads {
  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;

    .row {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .ui.grid + .ui.grid {
    margin-top: 0;
  }
}

/*------------------------------------*-headerTop-*----------------------------------*/
.crossRoads {
  .headerTop {

    .ui.search,
    .ui.button,
    .ui.buttons,
    .ui.selection,
    .field {
      float: right;
      margin: 0 0 10px 10px !important;
    }

    .field {
      .ui.selection {
        margin: 0 !important;
        min-width: 240px;
      }
    }

    .ui.buttons {
      .ui.button {
        margin: 0 !important;
        float: none;
      }
    }
  }

  .btnToggle {
    .field {
      margin: 5px 10px 6px 10px !important;
    }

    .ui.toggle.checkbox {
      min-height: auto;
    }
  }
}

/*------------------------------------*-Button-*----------------------------------*/
.crossRoads {
  .ui.button {
    font-family: 'SegoeUI-Regular', sans-serif;
    Font-size: 14px;
    font-weight: normal;
    border: 0;
    padding: 12px 20px !important;
    margin-right: 0;
    outline: none !important;
    min-width: 120px;

    i {
      color: #262626;
    }
  }

  .ui.buttons.btnSwitch {
    .ui.button {
      background: #d3d7d9;
      color: #7e8081;
      min-width: auto;

      &:hover {
        background: #c0c4c8;
      }
    }

    .ui.positive.button,
    .ui.active.button {
      background: #f5b439;
      color: #262626;

      &:hover {
        background: #eea111;
      }

      &:active, &:focus {
        background: #f5b439;
      }
    }
  }

  .ui.primary.button {
    background: #f5b439;
    color: #262626;

    &:hover, &:active, &:focus {
      background: #eea111;
    }
  }

  .ui.secondary.button {
    background: #bac2c7;
    color: #262626;

    &:hover, &:focus {
      background: #adb6ba;
    }
  }

  .ui.labeled.icon.left.button {
    padding-left: 40px !important;
    padding-right: 15px !important;
  }

  .ui.labeled.icon.right.button {
    padding-left: 15px !important;
    padding-right: 40px !important;
  }

  .ui.button.circular {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    padding: 7px !important;
    min-width: auto;
  }

  .ui.red.button {
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }

  .ui.button.asAnchor {
    background: none !important;
    padding: 3px 0 !important;
    color: #007cff;
    min-width: unset;

    &:hover {
      text-decoration: underline;

      i {
        text-decoration: none;
      }
    }
  }

  .ui.button.basic.iconBtn {
    min-width: auto;
    padding: 12px 11px !important;
    box-shadow: none !important;
    border: 1px solid #dededf;

    i {
      color: #96989d;
    }

    &:hover, &:focus {
      background: none !important;
      border: 1px solid #262626;

      i {
        color: #262626;
      }
    }
  }

  .btn-block {
    width: 100%;
    font-size: 14px;
  }

  .ui.button.btnSquare {
    min-width: auto;
    padding: 12px !important;
  }

  .btnToggle {
    label {
      float: left;
      padding-top: 0 !important;
      margin: 0 10px 0 0 !important;
    }
  }
}

/*--------------------------------------------*-Header-*--------------------------------*/
.crossRoads {
  .ui.menu {
    border: none !important;

    .item {
      padding: 20px 5px;

      &:before {
        display: none;
      }

      i {
        font-size: 16px !important;
      }
    }

    .item.dropdown {
      border: none;
      padding: 10px 20px;

      i.icon {
        margin-right: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      .menu {
        width: 180px;

        .item + .item {
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }
}

/*------------------------------*-card-*--------------------------------*/
.crossRoads {
  .ui.card {
    width: 100%;
    box-shadow: none;
    margin-bottom: 20px !important;
    margin-top: 0;

    .header {
      padding: 15px;
    }

    .content.extra {
      border-top: 1px solid #e9e9e9 !important;
    }
  }

  .ui.card > .content {
    padding: 15px;
    border-top: 1px solid #e9e9e9 !important;
  }
}

/*------------------------------*-Segment-*--------------------------------*/

/*-------------------------------------*Left-Nav-*------------------------------------------------*/
.mainLogo {
  margin: 0 15px;
  padding: 10px 0 7px 0;
  background: #fff;
  border-radius: 0 0 0 40px;
  text-align: center;
  transition: none;
}

.nav-style.sidenav---sidenav---_2tBP {
  background: #21293A;
  height: 100vh;
  overflow: visible scroll;
  position: fixed;
  width: 75px;

  .sidenav---sidenav-toggle---1KRjR {
    span {
      display: none;
    }

    i.fa-bars {
      font-size: 24px !important;
      color: #f5b439;
    }

    &:hover {
      i.fa-bars {
        color: #eea111;
      }
    }
  }
}

/*-------------------------Collapsed--------------------------*/
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
  .sidenav---sidenav-toggle---1KRjR {
    right: -5px;
    top: -5px;
    color: #fff;
    height: 40px;
  }

  .mainLogo {
    background: url("../images/logoMini.png");
    width: 58px;
    height: 57px;
    padding: 0;
    margin: 10px 3px;

    img {
      display: none;
    }
  }
}

/*-------------------------Expanded--------------------------*/
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  width: 300px;
  height: 100vh;

  .sidenav---sidenav-toggle---1KRjR {
    right: 15px;
    background-color: #fff;
    border-radius: 0 0 15px 15px;
    padding: 10px 0;
    width: 60px;
    height: 60px;

    &:before {
      content: "";
      position: absolute;
      width: 23px;
      height: 18px;
      left: -23px;
      top: 0;
      background: url("../images/shapeCorner.png") no-repeat;
    }
  }
}

.sidenav---sidenav-toggle---1KRjR.sidenav---icon-bar---u1f02 {
  background-color: #21293a;
  display: none;
}

.active.sidenav---sidenav-navitem---uwIJ- {
  &:before {
    content: " ";
    position: absolute;
    width: 5px;
    height: 50px;
    background: #f5b439;
    border-radius: 0 6px 6px 0;
    top: 0;
    left: 0;
  }

  .sidenav---navicon---3gCRo {
    i {
      color: #f5b439 !important;
    }
  }

  .sidenav---navtext---1AE_f {
    a {
      color: #f5b439 !important;
      text-decoration: underline;
    }
  }
}

.sidenav---sidenav-nav---3tvij {
  margin-top: 30px;

  .sidenav---sidenav-navitem---uwIJ- {
    .sidenav---navitem---9uL5T, .sidenav---sidenav-nav---3tvij {
      .sidenav---sidenav-navitem---uwIJ- {
        .sidenav---navitem---9uL5T i,
        .sidenav---sidenav-nav---3tvij {
          .sidenav---sidenav-navitem---uwIJ- {
            .sidenav---navitem---9uL5T a {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

.sidenav---navtext---1AE_f a,
.sidenav---navicon---3gCRo i {
  color: #fff !important;
  font-size: 16px !important;
}

.sidenav---sidenav-navitem---uwIJ- {
  .sidenav---navicon---3gCRo {
    opacity: 1 !important;
  }

  &:hover {
    .sidenav---navtext---1AE_f a, .sidenav---navicon---3gCRo i {
      color: #f5b439 !important;
    }
  }
}


.sidenav---sidenav-navitem---uwIJ-:hover {
  .sidenav---navitem---9uL5T a,
  .sidenav---sidenav-navitem---uwIJ-:hover {
    .sidenav---navitem---9uL5T {
      .sidenav---navicon---3gCRo i {
        color: #f5b439 !important;
        font-size: 10px;
      }
    }
  }
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
  background: none !important;
}

/*=======================================================LEFT-NAV END====================================*/


/*---------------------*-mainTitle-*---------------*/

.crossRoads {
  .mainTitle {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 20px;
    color: #262d3d;
    text-transform: uppercase;
    margin: 6px 0 17px 0;
    float: left;
  }

  .subTitle {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 16px;
    color: #262D3D;
    font-weight: normal;
    float: left;
    margin: 0 10px 0 0;
  }

  .subHeaderInner {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 16px;
    color: #262D3D;
    background: #FDF0D8;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
}

.crossRoads {
  .dropdown-menu {
    border-radius: 0 0 5px 5px;
    padding: 0;
    top: -2px !important;
    border: none;
    min-width: 144px;
    left: -15px !important;
    cursor: pointer;
  }
}

.ui.dropdown {
  .menu.contactMenu {
    right: 0;
    left: auto;
  }
}

/*=======================================================TABLE====================================*/
.crossTable.ui.table {
  margin-bottom: 0;
  border: none;

  thead {
    tr {
      th {
        border-bottom: 1px solid #868686;
        border-left: 0;
        border-top: none;
        padding-top: 0;
        font-size: 14px;
        font-family: 'SegoeUI-Bold', sans-serif;
        font-weight: normal;
        color: #262D3D;
        background: transparent;
        text-align: center;
        white-space: break-spaces;
        z-index: 1;


        &:hover {
          background-color: #fff !important;
          z-index: 1;

          i {
            color: #262D3D;
          }
        }

        &.active {
          border-bottom: 2px solid #f5b439;
        }

        i {
          color: #cecfd1;
        }

        &:first-child {
          text-align: left;
        }
      }

      th {
        &:nth-last-child(1) {
          text-align: center;
          width: 200px;
        }
      }
    }
  }

  tbody {
    tr {


      &:hover {
        color: #323232;
        background-color: #f8f8f8 !important;
      }
    }

    td {
      border-bottom: 1px solid #e9e9e9;
      border-top: none;
      font-size: 14px;
      font-family: 'SegoeUI-Regular', sans-serif;
      color: #262626;
      text-align: center;
      min-width: 210px;

      &:nth-child(1) {
        text-align: center;
      }

      &:first-child:only-child {
        text-align: center !important;
      }

      &:first-child {
        text-align: left;
      }

      i {
        margin: 0 5px;

        &:hover {
          color: #6c6d6f;
        }
      }
    }

    td[data-heading="Action"] {
      white-space: nowrap;
    }
  }

  tfoot {
    tr {

      &:hover {
        color: #323232;
        background-color: #f8f8f8 !important;
      }
    }

    td {
      border-bottom: 1px solid #f1f1f1;
      border-top: none;
      font-size: 14px;
      font-family: 'SegoeUI-Regular', sans-serif;
      color: #323232;
      text-align: center;

      &:first-child {
        text-align: left;
      }

      i {
        color: #97999E;
        margin: 0 5px;

        &:hover {
          color: #F5B536;
        }
      }
    }
  }
}

.no-more-tables {
  overflow-x: auto !important;
}

.frozen {
  max-width: 100%;
  max-height: 60vh;
  overflow: auto;
  position: relative;
}

.frozen table {
  position: relative;
  border-collapse: collapse;
}

.frozen thead th {
  background: #fff !important;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}

/*=========================================================PAGINATION======================================*/

.crossRoads {
  .ui.mini.pagination {
    margin: 15px 0 0 10px;
    background: transparent;
    border: none;
    box-shadow: none;

    a {
      padding: 10px 10px;
      font-size: 14px;

      &:focus {
        outline: none;
      }
    }

    a.active.item {
      background: #f5b439;
      text-align: center;
      padding: 10px 14px;
      color: #262626;
      border-radius: 4px;
      min-width: auto;

      &:hover {
        background: #eea111;
      }
    }
  }

  .rowCount {
    .field {
      margin-bottom: 0 !important;
    }

    .ui.fluid.dropdown {
      margin-left: 0 !important;
      width: 80px;
    }
  }
}

/*=======================================================PAGINATION END====================================*/

.crossRoads {
  .segment {
    border: none !important;
    box-shadow: none !important;
  }
}

/*=======================================================LOGIN PAGE====================================*/


.crossRoads {
  .logInWrap {
    &:after {
      content: '';
      position: absolute;
      background: #f5b439;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50%;
    }
  }

  .ui.card.loginFormCard.resetForm {
    min-height: 550px;

    .loginImg {
      margin-top: 28%;
    }

    .loginForm {
      padding-right: 0;
    }
  }

  .ui.card.loginFormCard {
    width: 767px !important;
    color: #ffffff;
    min-height: 430px;
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, .3) !important;
    background: #21293A !important;
    position: absolute !important;
    left: 0;
    right: 0;
    z-index: 999;
    border: 0;
    top: 20%;
    padding: 2% !important;
    margin: 0 auto !important;

    .content {
      border-top: none !important;
    }

    .loginImg {
      width: 100%;
      margin-top: 40px;
      position: absolute;
      left: -90px;
      top: -50px;
    }

    .loginForm {
      float: left;
      padding-top: 10%;
      padding-right: 15%;
      width: 100%;

      .ui.form {
        float: left;
        width: 100%;
        margin-top: 30px;

        .fields {
          margin-bottom: 0;

          .field {
            margin-bottom: 0 !important;

            label {
              display: none !important;
            }

            .prompt.label {
              position: absolute !important;
              top: -10px !important;
              right: 20px !important;
            }
          }

          input {
            font-size: 14px !important;
            background: transparent !important;
            border-top: 0 solid transparent !important;
            border-left: 0 solid transparent !important;
            border-right: 0 solid transparent !important;
            border-bottom: 1px solid #96989D !important;
            box-shadow: none !important;
            color: #f5b439 !important;
            border-radius: 0 !important;
            margin-bottom: 20px !important;

            &:-webkit-autofill {
              box-shadow: none !important;
              background: transparent !important;
              background: none !important;
              color: #f5b439 !important;
            }
          }
        }
      }
    }
  }
}

.forgotText, .form-resend-otp {
  font-size: 14px;
  color: #f5b439;
  margin-top: 10px;

  &:hover {
    color: #ffffff;
  }
}

.crossRoads {
  .loginForm.reset {
    padding-top: 2%;

    .form-group {
      label {
        float: left;
        font-size: 13px;
        margin-bottom: 0;
        color: #96989D;
        font-family: 'SegoeUI-Regular', sans-serif;
      }
    }
  }
}

p.pwdRequirementsTitle {
  position: absolute;
  font-size: 12px;
  background: #21293a;
  top: 22px;
  left: 0;
  right: 0;
  width: 276px;
  margin: 0 auto !important;
}

.pwdRequirements {
  padding: 15px !important;
  margin-top: 30px;
  border: 1px solid #ffffff;
  float: left;
  width: 100%;

  li {
    float: left;
    width: 50%;
    padding: 5px 0;
    list-style: none;
    font-size: 11px !important;
    text-align: left;
    font-weight: 500;
    color: #f5b439;
  }

  svg {
    font-size: 12px;
    float: left;
    margin-top: 1px;
    margin-right: 3px;
  }
}

.highLight {
  color: #f5b439;
}

.txtBlack {
  color: #000000 !important;
}

.txtGray {
  color: #999999;
}

/*=======================================================LOGIN PAGE END====================================*/

/*=======================================================FORM CSS====================================*/

.crossRoads {
  .customCheckGroup {
    .ui.checkbox {
      label {
        color: #0f0f10 !important;
        margin-right: 20px;
      }
    }
  }

  label {
    color: #96989D;
    font-size: 13px !important;
    font-weight: normal !important;
    text-transform: uppercase;
    display: block;
    margin-bottom: 4px;
  }

  .field {
    margin-bottom: 15px !important;
  }

  .ui.form {
    .fields {
      margin-bottom: 0;

      .selection.dropdown {
        min-width: auto;
      }
    }

    .field.disabled {
      opacity: 1;
    }

    .field {
      .customRadioGroup {
        label {
          color: #0f0f10 !important;
          margin-right: 20px;
        }
      }

      .questionnaire-chk-box {
        label {
          color: #000000 !important;

          span, i {
            color: #000000 !important;
          }
        }

        label > span > i {
          color: #000000 !important;
        }
      }

      label {
        color: #96989D;
        font-size: 13px !important;
        font-weight: normal !important;
        text-transform: uppercase;
      }

      .prompt.label {
        position: absolute;
        top: -10px;
        right: 20px;
      }

      input {
        &::-webkit-input-placeholder {
          font-style: italic;
        }

        &::-moz-placeholder {
          font-style: italic;
        }

        &:-ms-input-placeholder {
          font-style: italic;
        }

        &:hover {
          border-color: rgba(34, 36, 38, 0.35);
        }
      }

      .ui.disabled.input {
        opacity: 1;
        color: #000000;

        border-radius: 4px;

        input {
          opacity: 1 !important;
          background: #f3f3f3 !important;
        }
      }
    }
  }

  .ui.form.filters {
    label {
      color: #5F5F61;
      margin-left: 2px;
    }

    .ui.input,
    .ui.selection.dropdown {
      border: 1px solid #ABA8A8;
      border-radius: 4px;
    }
  }

  .ui.search {
    .prompt {
      border-radius: 4px;
    }
  }

  .ui.search.w100 {
    .ui.input {
      width: 100%;
    }
  }

  input {
    &:focus {
      border-color: #f5b439 !important;

    }
  }

  .ui.selection.active.dropdown {
    border-color: #f5b439 !important;

    .menu {
      border-color: #f5b439 !important;
    }
  }

  .ui.scrolling.dropdown {
    .menu {
      min-width: calc(100% + 2px) !important;
    }
  }
}

.labeledInput {
  input {
    width: 80px;
  }
}

/*=======================================================FORM CSS END====================================*/

/*---------------------------------*-disableList-*--------------------------------*/
.crossRoads {
  .disabledList {
    .field {
      clear: both;
      margin-bottom: 0 !important;

      .ui.input.disabled {
        width: 60%;
        opacity: 1;

        input {
          font-family: 'SegoeUI-Semibold', serif;
          font-size: 14px;
          color: #262626;
          padding: 5px 0;
          border: 0;
        }

        input::-ms-input-placeholder {
          font-style: italic !important; /* IE11 needs the !important flag */
          font-weight: normal !important;
        }

        /* (...) Other vendor prefixed rule sets omitted for brevity */

        input::placeholder {
          font-style: italic !important;
          font-weight: normal !important;
        }
      }
    }

    label {
      font-size: 13px;
      color: #96989D;
      text-transform: uppercase;
      text-align: right;
      width: calc(40% - 20px);
      margin: 3px 12px 0 0;
      float: left;
    }
  }
}

/*---------------------------------*-Chart-*--------------------------------*/
.chartHolder {
  overflow-x: auto;
  overflow-y: hidden;

  div:first-child {
    min-width: 550px;
  }
}

/*---------------------------------*-List-*--------------------------------*/
.ui.list.listStyle {
  .item {
    padding: 10px 0 !important;
    border-bottom: 1px solid #e9e9e9 !important;
  }

  .content {
    padding: 0;
    display: inline-block;
  }

  i.icon {
    &:hover {
      color: #6c6d6f;
    }
  }
}

.ui.list.listStyle.listWithAction {
  max-height: 530px;
  overflow-y: auto;

  .item {
    padding: 0 !important;
  }

  .content {
    padding: 10px 0;

    &:first-child {
      width: calc(100% - 130px);

      &:hover {
        padding-left: 10px;
      }
    }
  }
}

/*---------------------------------*-Tabs-*--------------------------------*/
.tabStyle {
  .ui.tabular.menu {
    border-bottom: 1px solid #868686 !important;

    .item {
      border-bottom: 3px solid transparent;
    }

    .active.item {
      border: none;
      border-bottom: 3px solid #f5b439;
    }
  }
}

/*---------------------------------*-dz-container-*--------------------------------*/
.dz-container {
  .dz-zone {
    padding: 45px 15px !important;
    font-size: 22px;
    text-transform: capitalize;
  }
}

/*---------------------------------*-Progress-bar-*--------------------------------*/
.ui.tiny.progress.indicating {
  margin-bottom: 0;
}

/*---------------------------------*-questionnaireBuilder-*--------------------------------*/
.questionnaireBuilder {
  .ui.list.listStyle.listWithAction {
    margin-bottom: 0 !important;

    .right.content {
      display: none !important;
    }
  }
}

.questionnaireList {
  max-height: 420px;
  overflow-y: visible;
  overflow-x: hidden;
}

.ui.list.responseTypeAbbreviation {
  width: 100%;
  text-align: right;
  background: #ebeff1;
  padding: 2px 8px 0 !important;
  margin-bottom: 10px !important;
  border-radius: 4px;

  .item {
    margin-left: 30px !important;
  }
}

/*---------------------------------*-datePicker-*--------------------------------*/
.datePicker {
  position: relative;

  .ui.input {
    border-radius: 4px;
    width: 100%;
  }
}

.datePickerCloseIcon {
  position: absolute;
  right: 22px;
  top: 31px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.8 !important;
  font-size: 0.85714286em !important;
}

.datePickerCloseIcon {
  &:hover {
    opacity: 1 !important;
  }
}

/*--------------------------------Modal--------------------------------*/
.ui.modal {
  .content {
    padding: 1rem !important;
  }
}

/*---------------------------------*-Earning Details-*--------------------------------*/

.earning-details-tab, .project-details-tab {
  background: #EBEFF1;
}

.earning-contact-detail {
  border-left: 5px solid #f5b636;
  height: 40px;
  padding-left: 10px;

  label {
    color: #989a9f !important;
  }
}

/*---------------------------------*-Questionnaire-*--------------------------------*/

.qs-tree {
  max-height: 560px;
  overflow-y: visible;
  overflow-x: hidden;
}

.qs-row-active {
  border: 2px solid rgb(255, 160, 160);
  box-shadow: 0 5px lightgray;
  margin: 50px 0 50px 0 !important;
  opacity: 1;
  padding: 2px !important;
}

.qs-row-overlay {
  opacity: 0.3;

  .qs-row-a {
    pointer-events: none;
  }

  .qs-row-q {
    pointer-events: none;
  }
}

.qs-row-display {
  opacity: 0.3;

  .qs-row-a {
    .radio, .checkbox {
      pointer-events: none;
    }
  }

  .qs-row-q {
    pointer-events: visible;
  }
}

.qs-logic {
  overflow-y: scroll;
  max-height: 313px;
}

.call-details {
  max-height: 750px;
  overflow-y: scroll;
}

/*---------------------------------*-Projects-*--------------------------------*/

.calls-ban-icon {
  margin: 0 0 !important;
  font-size: 12px !important;
}

.project-billing {
  background: rgb(227, 227, 227);
  float: left;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 30px 15px;
}

/*--------------------------------Responsive--------------------------------*/
@media (max-width: 1456px) {
  .crossRoads {
    .disabledList {
      label {
        text-align: left;
        width: 100%;
      }

      .ui.input.disabled {
        min-width: 100%;

        input {
          padding: 0 15px 10px 10px !important;
        }
      }

    }
  }

}

@media (max-width: 1199px) {
  .customGrid.ui[class*="three column"].grid > .row > .column,
  .customGrid.ui[class*="three column"].grid > .column:not(.row) {
    width: 50%;
  }
  .ui.list.responseTypeAbbreviation {
    text-align: left;

    .item {
      margin-left: 0 !important;
      margin-right: 20px !important;
    }
  }
}

@media (max-width: 991px) {
  .no-more-tables {
    border: 1px solid #dfdfdf;
    border-bottom: none;
    border-radius: 4px;

    td, tr {
      display: block;
    }

    tbody {
      tr:nth-child(even) {
        background: #f8f8f8;
      }
    }

    td.text-center,
    th.text-center,
    td.text-right,
    th.text-right {
      text-align: left !important;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      td:first-child {
        border-left: none !important;
      }
    }

    .table {
      margin-bottom: 0;
    }

    .crossTable.ui.table {
      tr {
        td {
          border: none;
          border-bottom: 1px solid #dfdfdf;
          position: relative;
          padding-left: 50% !important;
          white-space: normal !important;
          //word-break: break-word;
          text-align: left;
          min-height: 40px;

          td {
            padding-left: 0 !important;

            &:before {
              width: 0;
            }
          }
        }

        td.py-0 {
          padding: 10px 10px 10px 50% !important;
        }

        td.pl10 {
          padding-left: 10px !important;
        }

        td.beforeNone {
          padding-left: 10px !important;
        }

        td.beforeNone.text-center {
          text-align: center !important;
        }

        &:hover {
          background: #f2f2f2 !important;
        }
      }
    }

    td:before {
      position: absolute;
      //top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      text-align: left;
      font-weight: bold;
      content: attr(data-heading);
    }

  }

  .crossRoads {
    .ui.card.loginFormCard {
      margin-top: 15%;
      width: 80% !important;
      height: auto;

      .loginImg {
        width: 200px;
        position: relative;
        top: -80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block !important;
      }

      .loginForm {
        float: left;
        padding-top: 0;
        padding-right: 0;
      }
    }
  }

  .questionnaireList {
    max-height: 350px;
  }
  .ui.list.listStyle.listWithAction {
    max-height: 220px;
  }
}

@media (max-width: 767px) {

  .ui.card.loginFormCard {
    margin-bottom: 40px !important;
  }

  .ui.card.loginFormCard.resetForm {
    position: relative !important;
    margin-top: 40px !important;
  }

  .pwdRequirements {
    li {
      width: 100%;
      padding: 0 0;
    }
  }

  .no-more-tables {
    .crossTable.ui.table {
      tr {
        padding: 0 !important;

        td {
          font-size: 13px;
          line-height: 14px;
          padding: 6px 10px 6px 50% !important;
          border-bottom: 1px solid #dfdfdf !important;
        }

        td.pl10 {
          padding-left: 10px !important;
        }
      }
    }
  }

  .crossRoads {
    .loginFormCard {
      .loginForm {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 575px) {
  .crossRoads {
    .headerTop {
      .ui.search {
        .ui.input {
          width: 100%;
        }
      }

      .ui.selection,
      .ui.search,
      .field {
        width: 100%;
      }
    }
  }
  .customGrid.ui[class*="three column"].grid > .row > .column,
  .customGrid.ui[class*="three column"].grid > .column:not(.row) {
    width: 100%;
  }
}

/* ================================ Print ====================================*/

@media print {
  body, html, .print-it {
    width: 100%;
    background-color: white !important;
    -webkit-print-color-adjust: exact !important;
    overflow: visible !important;
  }

  .no-print {
    display: none !important;
  }

  .print-it {
    margin-left: 0 !important;
    display: inline !important;

    .qs-tree {
      overflow-x: visible !important;
    }
  }

  .page-Break {
    break-inside: avoid !important;
  }
}

/* ================================ Call Log ====================================*/

.close-modal {
  min-width: 0 !important;
}


/* =============================== Comment Cleaner ================================*/

.cc-textarea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  background: #FFFFFF;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 0 0 0 transparent inset;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1em;
  font-family: sans-serif;
  height: 100px;
  line-height: 1.2857;
  margin: 0;
  outline: none;
  overflow: auto;
  padding: 0.78571429em 1em;
  resize: vertical;
  tap-highlight-color: rgba(255, 255, 255, 0);
  transition: color 0.1s ease, border-color 0.1s ease;
  width: auto;
}

/* ================================ MultiSelectListBox ====================================*/
:root {
  --mslb-bg-color: #fff;
  --mslb-font-color: #000;
  --mslb-border-color: #e0e0e0;
  --mslb-btn-font-color: #000;
  --mslb-btn-bg-add-color: #f5b439;
  --mslb-btn-bg-remove-color: #DB2828;
  --mslb-item-hover-bg-color: #f5f5f5;
  /* ============================== service recoveries ===================================*/

  .multi-select > div:first-child {
    flex-direction: row-reverse !important;
  }

  .styles_root__1pA_R {
    border: 2px solid #ebeff1;
  }
}

/* ======================================== question bank ====================================*/
.ques-denied, .ans-denied {
  background: #fff6f6!important;
  color: #9f3a38!important;
}

/* ========================================= Verify Otp Form ===============================*/
.resend-otp {
  font-size: 14px;
  background: none !important;
  padding: 3px 0 !important;
  color: #f5b439 !important;
  min-width: unset;

  &:hover {
    text-decoration: underline !important;

    i {
      text-decoration: none;
    }
  }
}
