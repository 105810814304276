/**
 here dev's can add custom classes that they need
 w/o disturbing layout and anything else
*/

/*======================================CUSTOM CLASSES=============================*/
.danger {
  color: #DB2828;
}

.qs-row {
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-transition: background-color 350ms ease-out;
  box-sizing: border-box;
  color: #333e48;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  //margin-bottom: 20px;
  max-width: 100%;
  position: relative;
  text-decoration: none;
  text-rendering: optimizelegibility;
  transition: background-color 350ms ease-out;
}

.qs-row.qs-row-active {
  zoom: 120%;
  -moz-transform: translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1.3);
}

.qs-v label {
  text-transform: capitalize !important;
}

.highlight-row {
  font-variant-numeric: oldstyle-nums;
  font-variant: petite-caps;
}

.bolder {
  font-weight: bolder !important;
}

.hand-pointer {
  cursor: pointer;
}

i.fas {
  cursor: pointer;
}

.invisible {
  display: none !important;
}

.alert {
  position: fixed !important;
  z-index: 99999;
  right: 280px !important;
  top: 0 !important;
  float: right;
  text-align: center !important;
  width: 320px;
  min-height: 60px;
  padding: 15px;
  font-size: 16px;
}

.boo {
  border: solid 1px red;
}

.pre-light-bg {
  background: #FFFFFF !important;
  margin: 10px;
  padding: 0;
}

.pre-block {
  background: #dedede;
  font-size: 14px;
  border-radius: 4px;
  color: #666;
}

.pre-block pre {
  font-size: 14px;
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.pre-block__title {
  padding: 20px 20px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.border-bottom {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.dz-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;

  .dz-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
}

.bbl {
  border-bottom: 1px solid #efefef !important;
}

.center-force {
  text-align: center !important;
}

.ui.page.modals {
  z-index: 1100;
}

.red {
  color: red;
}

.dnd-card {
  padding: 0.5rem;
  margin-bottom: .5rem;
  background-color: white;
  cursor: move;
}

.dnd-chosen-card {
  border: 1px dashed gray;
  cursor: grab;
  background-color: white !important;
}

.ui.fluid.search .results {
  width: 200% !important;
}

.grid-progress-bar.ui.table {
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #000 !important;
  border-left: none !important;
  border-top: 1px solid #fff !important;
  margin: 0 !important;
  min-width: 10px !important;

  tr {
    max-width: 250px !important;
    width: 100%;
  }

  td {
    border-bottom: 1px solid #fff !important;
    border-right: 1px solid #000 !important;
    border-top: 1px solid #fff !important;
    height: 14px !important;
    margin: 0 !important;
    min-height: 10px !important;
    min-width: 1px !important;
    padding: .5em 2px !important;
  }

  tr {
    td:first-child {
      border-left: 1px solid #000 !important;
    }

    td:last-child {
      padding: 0;
    }
  }
}

body.crossRoads.dev {
  border-top: 7px solid greenyellow;
}

body.crossRoads.stage {
  border-top: 7px solid #f5b439;
}