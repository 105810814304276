// Import Fonts
@import "_fonts";

// Import CSS & SCSS Files
@import "_custom";

// Custom CSS
@import "_custom_dev";

// Libs

@import 'semantic';

// Import Font Awesome
@import "~@fortawesome/fontawesome-free/css/all.min.css";
