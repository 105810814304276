
/*======================================FONTS=============================*/
@font-face {
  font-family: 'SegoeUI-Regular';
  src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.eot');
  src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.svg') format('svg'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff') format('woff'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff2') format('woff'),
  url('../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.SegoeUI-Regular{
  font-family: 'SegoeUI-Regular' !important;
}

@font-face {
  font-family: 'SegoeUI-Semibold';
  src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot');
  src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg') format('svg'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff') format('woff'),
  url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf') format('truetype'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.SegoeUI-Semibold{
  font-family: 'SegoeUI-Semibold' !important;
}
@font-face {
  font-family: 'SegoeUI-Bold';
  src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.eot');
  src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.svg') format('svg'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'),
  url('../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf') format('truetype'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.SegoeUI-Bold{
  font-family: 'SegoeUI-Bold' !important;
}
@font-face {
  font-family: 'SegoeUI-Light';
  src: url('../fonts/SegoeUI-Light/SegoeUI-Light.svg') format('svg'), url('../fonts/SegoeUI-Light/SegoeUI-Light.woff') format('woff'),
  url('../fonts/SegoeUI-Light/SegoeUI-Light.ttf') format('truetype'), url('../fonts/SegoeUI-Light/SegoeUI-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.SegoeUI-Light{
  font-family: 'SegoeUI-Light' !important;
}
/*======================================FONTS END=============================*/